import { Fragment, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { LoadingComponent } from '../src/components/atom/Loading';
import useAuth from '../src/features/auth/useAuth';

const GameSearchComponent = dynamic(
	() => import('../src/components/molecules/GameSearchComponent'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const GameCategoryComponent = dynamic(
	() => import('../src/components/molecules/GameCategoryComponent'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const GameLotteryResultComponent = dynamic(
	() => import('../src/components/molecules/GameLotteryResultComponent'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const AnnouncementComponent = dynamic(
	() => import('../src/components/molecules/AnnouncementComponent'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const AuthSectionComponent = dynamic(
	() => import('../src/components/molecules/AuthSectionComponent'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const JackpotProgressComponent = dynamic(
	() => import('../src/components/molecules/JackpotProgressComponent'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const RealtimeTransactionComponent = dynamic(
	() => import('../src/components/molecules/RealtimeTransactionComponent'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const GamepopularComponent2 = dynamic(
	() => import('../src/components/molecules/GamepopularComponent2'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const GameRecommendedComponent2 = dynamic(
	() => import('../src/components/molecules/GameRecommendedComponent2'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);
const GameProviderComponent2 = dynamic(
	() => import('../src/components/molecules/GameProviderComponent2'),
	{ ssr: false, loading: () => <LoadingComponent /> }
);

const Home = () => {
	const { isLoggedIn } = useAuth();
	const [brandSectionOrder] = useState([
		'announcement',
		'authSection',
		'gamecategory',
		// 'jackpotprogress'
		'gamesearch',
		'gameprovider',
		// 'gamelotteryresult',
		'gamePopular',
		'gamerecommended',
		'realtimetransaction'
	]);

	const sectionOrderComponent = useMemo(() => {
		return brandSectionOrder.map((item, index) => {
			const sectionSlideMode = index % 2 === 0 ? 'fromLeft' : 'fromRight';
			let component;
			switch (item) {
				// case 'banner':
				// 	component = <BannerMain slideMode={sectionSlideMode} />;
				// 	break;
				case 'authSection':
					if (!isLoggedIn) component = <AuthSectionComponent className="mb-section" />;
					break;
				case 'announcement':
					component = <AnnouncementComponent />;
					break;
				case 'gamecategory':
					component = <GameCategoryComponent />;
					break;
				case 'gamesearch':
					component = (
						<div className="hidden md:block">
							<GameSearchComponent slideMode={sectionSlideMode} />
						</div>
					);
					break;
				case 'gameprovider':
					component = <GameProviderComponent2 />;
					break;
				case 'gamelotteryresult':
					component = <GameLotteryResultComponent slideMode={sectionSlideMode} showTitle />;
					break;
				case 'gamerecommended':
					component = <GameRecommendedComponent2 />;
					break;
				case 'gamePopular':
					component = <GamepopularComponent2 />;
					break;
				case 'realtimetransaction':
					component = <RealtimeTransactionComponent />;
					break;
				case 'jackpotprogress':
					component = <JackpotProgressComponent />;
					break;
				default:
					component = null;
					break;
			}
			return component ? <Fragment key={index}>{component}</Fragment> : null;
		});
	}, [isLoggedIn]);
	return <>{sectionOrderComponent}</>;
};

export default Home;
