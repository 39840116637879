import Image from 'next/image';
import { customImageLoader } from '../../../helpers/custom-image-loader';
import { cn } from '../../../../utils/cn';

export const LoadingComponent = ({ className }) => {
	return (
		<>
			<div className="w-full flex justify-center items-center">
				<div className={cn('relative w-10 h-10 aspect-square', className)}>
					<Image
						src="/icons/loading/loading-2.gif"
						loader={customImageLoader}
						layout="fill"
						objectFit="contain"
						priority
					/>
				</div>
			</div>
		</>
	);
};
