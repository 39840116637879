import Image from 'next/image';
import { cn } from '../../../../utils/cn';
import { customImageLoader } from '../../../helpers/custom-image-loader';

export const LoadingPage = ({ className }) => {
	return (
		<div className="w-full h-screen flex justify-center items-center">
			<div className={cn('relative w-16 h-16 lg:w-32 lg:h-32 aspect-square', className)}>
				<Image
					src="/icons/loading/loading-2.gif"
					loader={customImageLoader}
					layout="fill"
					objectFit="contain"
					priority
				/>
			</div>
		</div>
	);
};
